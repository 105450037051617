import {
    Component,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit} from '@angular/core';
import { from, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@core/config.service';
import { SanitizerService } from '@app/services/sanitizer/sanitizer.service';
import { CTA } from '@app/features/homepage/components/new-hero/interfaces/cta.interface';
import { WindowSizeService } from '@app/shared/services/window-size/window-size.service';
import { WindowSizeEnum } from '@app/shared/services/window-size/window-size.enum';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Brands } from '@app/shared/utils/utils.enum';

@Component({
    selector: 'app-new-hero',
    templateUrl: 'new-hero.component.html',
    styleUrls: ['new-hero.component.scss']
})
export class NewHeroComponent implements OnInit, OnDestroy {
    @Input() ME?: any;

    public titleMobile?: string;
    public titleDesktop?: string;
    public headerMobile?: string;
    public headerDesktop?: string;

    public videoFirstCTA: CTA;
    public mobileVideoFirstCTA: CTA;
    public videoDetailsCTA: CTA;
    public mobileVideoDetailsCTA: CTA;

    public isFast: boolean;
    public hasFirstCTA: boolean;
    public isMobile: boolean;
    public siteId: string;

    @HostBinding('style.height') componentHeight: string;

    private destroy$ = new Subject<void>();

    constructor(
        private resourceBundleService: ResourceBundleService,
        private configService: ConfigService,
        private sanitizerService: SanitizerService,
        private windowSizeService: WindowSizeService,
        private logger: LoggerService
    ) {}

    ngOnInit() {
        this.siteId = this.configService.getValue('siteId');

        this.isMobile = this.windowSizeService.getDevice() === WindowSizeEnum.Mobile;

        this.windowSizeService
            .onResize()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (device) => {
                    this.isMobile = device === WindowSizeEnum.Mobile;
                }
            });

        window['heroMedia'] = [];

        this.updateHeight(window.innerWidth);

        from(this.resourceBundleService.get('heroes'))
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    const descriptions = response?.heroes[0]?.descriptions;
                    this.isFast = /\bFast\b/i.test(response?.heroes[0]?.name);
                    this.setUpHero(descriptions, this.isFast);
                },
                error: (error) => {
                    this.logger.error('Error getting heroes resource bundle', error);
                }
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.updateHeight(event.target.innerWidth);
    }

    updateHeight(width: number) {
        if (width >= 1200 && (this.siteId === Brands.HKDL || this.siteId === Brands.DCL)) {
            this.componentHeight = '57vw';
        } else {
            this.componentHeight = null; // CSS will handle the rest of the screen sizes.
        }
    }

    setUpHero(descriptions, isFast: boolean) {
        const analyticsSiteId = this.siteId.toUpperCase();

        const firstCtaDesktop = isFast ? descriptions?.videoFirstCTA?.sections : descriptions?.videoPlayCTA?.sections;
        const firstCtaMobile = isFast ? descriptions?.mobileVideoFirstCTA?.sections : descriptions?.mobileVideoPlayCTA?.sections;
        const detailsCtaDesktop = descriptions?.videoDetailsCTA?.sections;
        const detailsCtaMobile = descriptions?.mobileVideoDetailsCTA?.sections;

        this.setHeadersAndTitles(descriptions, isFast);

        if (firstCtaDesktop && firstCtaMobile) {
            this.hasFirstCTA = true;

            this.mobileVideoFirstCTA = this.setCTA(firstCtaMobile, false, analyticsSiteId, isFast);
            this.videoFirstCTA = this.setCTA(firstCtaDesktop, false, analyticsSiteId, isFast);
        }

        this.mobileVideoDetailsCTA = this.setCTA(detailsCtaMobile, true, analyticsSiteId, isFast);
        this.videoDetailsCTA = this.setCTA(detailsCtaDesktop, true, analyticsSiteId, isFast);
    }

    setHeadersAndTitles(descriptions, isFast: boolean) {
        if (isFast) {
            this.headerMobile = this.sanitizerService.decodeHTML(descriptions?.mobileVideoTitle?.sections?.header);
            this.headerDesktop = this.sanitizerService.decodeHTML(descriptions?.videoTitle?.sections?.header);
        }
        this.titleMobile = this.sanitizerService.decodeHTML(descriptions?.mobileVideoTitle?.sections?.title);
        this.titleDesktop = this.sanitizerService.decodeHTML(descriptions?.videoTitle?.sections?.title);
    }

    setCTA(ctaInfo, isDetails: boolean, analyticsSiteId: string, isFast: boolean): CTA {
        let cta: CTA = {
            alt: this.sanitizerService.decodeHTML(ctaInfo?.alt),
            analyticsLinkId: ctaInfo.analyticsLinkId,
            title: this.sanitizerService.decodeHTML(ctaInfo?.title),
            target: ctaInfo?.link?.trim() ? ctaInfo.link : '_self',
            icon: ctaInfo?.icon,
            ctaURL: ctaInfo?.ctaURL
        };

        if (isDetails) {
            cta.analyticsLinkId = `${analyticsSiteId}_HPMP_Billboard_${cta.analyticsLinkId}_Link`;
            cta.analyticsLinkIdFullscreen = `${analyticsSiteId}_HPMP_InVideo_${cta.analyticsLinkId}_Link`;
        }

        if (!isFast) {
            cta.alt = this.sanitizerService.decodeHTML(ctaInfo?.title);
            cta.target = ctaInfo?.target;
            cta.ctaURL = ctaInfo?.link;
        }

        return cta;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

declare global {
    interface Window {
        MEConfig: any;
        ME: any;
    }
}
