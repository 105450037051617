<div class="{{ 'hero-container ' + siteId }}" [ngClass]="{ 'legacy': !isFast }">
    <div class="hero-me me" id="media-engine-hero"></div>
    <section class="title-and-ctas">
        <ng-container *ngIf="isMobile">
            <h1 *ngIf="headerMobile && isFast"
                class="cta-header"
                [innerHTML]="headerMobile | safeHtml">
            </h1>
            <h1 *ngIf="titleMobile"
                class="cta-title"
                [innerHTML]="titleMobile | safeHtml">
            </h1>
            <section class="ctas">
                <div class="ctas-holder">
                    <a *ngIf="hasFirstCTA"
                       class="cta first-cta"
                       aria-hidden="false"
                       href="{{mobileVideoFirstCTA?.['ctaURL'] | absTrimmer:true:true}}"
                       name="&lid={{mobileVideoFirstCTA?.['analyticsLinkId']}}"
                       title="{{mobileVideoFirstCTA?.['alt']}}"
                       target="{{mobileVideoFirstCTA?.['target']}}">
                        <img *ngIf="mobileVideoFirstCTA?.['icon'] && isFast"
                             src="{{mobileVideoFirstCTA?.['icon']}}"
                             class="button-icon"
                             aria-hidden="true">
                        {{mobileVideoFirstCTA?.['title']}}
                        <span *ngIf="!isFast" class="icon"  aria-hidden="true"></span>
                    </a>
                    <a class="cta details-cta"
                        href="{{mobileVideoDetailsCTA?.['ctaURL'] | absTrimmer:true:true}}"
                        name="&lid={{mobileVideoDetailsCTA?.['analyticsLinkId']}}"
                        title="{{mobileVideoDetailsCTA?.['alt']}}"
                        target="{{mobileVideoDetailsCTA?.['target']}}"
                        aria-hidden="false">
                        <img *ngIf="mobileVideoDetailsCTA?.['icon']"
                            src="{{mobileVideoDetailsCTA?.['icon']}}"
                            class="button-icon"
                            aria-hidden="true">
                        {{mobileVideoDetailsCTA?.['title']}}
                        <span *ngIf="!isFast" class="icon"  aria-hidden="true"></span>
                    </a>
                </div>
            </section>
        </ng-container>
        <ng-container *ngIf="!isMobile">
            <h1 *ngIf="headerDesktop && isFast"
                class="cta-header"
                [innerHTML]="headerDesktop | safeHtml"
            ></h1>
            <h1 *ngIf="titleDesktop"
                class="cta-title"
                [innerHTML]="titleDesktop | safeHtml"></h1>
            <section class="ctas">
                <div class="ctas-holder">
                    <a *ngIf="hasFirstCTA"
                        class="cta first-cta"
                        href="{{videoFirstCTA?.['ctaURL'] | absTrimmer:true:true}}"
                        name="&lid={{videoFirstCTA?.['analyticsLinkId']}}"
                        title="{{videoFirstCTA?.['alt']}}"
                        target="{{videoFirstCTA?.['target']}}"
                        aria-hidden="false"
                    >
                        <img
                            *ngIf="videoFirstCTA?.['icon']"
                            src="{{videoFirstCTA?.['icon']}}"
                            class="button-icon"
                            aria-hidden="true">
                        {{videoFirstCTA?.['title']}}
                        <span *ngIf="!isFast" class="icon"  aria-hidden="true"></span>
                    </a>
                    <a
                        class="cta details-cta"
                        href="{{videoDetailsCTA?.['ctaURL'] | absTrimmer:true:true}}"
                        name="&lid={{videoDetailsCTA?.['analyticsLinkId']}}"
                        title="{{videoDetailsCTA?.['alt']}}"
                        target="{{videoDetailsCTA?.['target']}}"
                        aria-hidden="false">
                        <img
                            *ngIf="videoDetailsCTA?.['icon']"
                            src="{{videoDetailsCTA?.['icon']}}"
                            class="button-icon"
                            aria-hidden="true">
                        {{videoDetailsCTA?.['title']}}
                        <span *ngIf="!isFast" class="icon"  aria-hidden="true"></span>
                    </a>
                </div>
            </section>
        </ng-container>
    </section>
</div>
